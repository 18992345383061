<template>
  <div class="page">
    <div class="top_box">
      <div class="pic_box">
        <img :src="detail.avatar" alt="" />
      </div>
      <div class="name">{{ detail.nickName }}</div>
    </div>
    <p class="apply_content">{{ detail.applyReason }}</p>
    <div class="refuse_btn" v-if="detail.auditStatus == 0" @click="refuse(2)">
      拒绝
    </div>
    <div class="agree_btn" v-if="detail.auditStatus == 0" @click="agree(1)">
      同意
    </div>
    <div
      class="remove_btn"
      v-if="detail.auditStatus != 0"
      @click="removeMessage()"
    >
      移除
    </div>
    <v-dialog
      confirmButtonColor="#FE9615"
      v-model="isDialog"
      @confirm="confirm"
    >
      <p class="dialog_text">{{ dialog_text }}？</p>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { auditUrl, getApplyDetailsUrl, removeAppInfoUrl } from "./api.js";
export default {
  name: "communityApplicationMessageDetail",
  data() {
    return {
      isDialog: false,
      dialog_text: "",
      id: null,
      detail: null,
      auditStatus: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let params = {
        id: this.id,
      };
      this.$axios
        .get(`${getApplyDetailsUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.detail = res.data;
          }
        });
    },
    refuse(status) {
      this.auditStatus = status;
      this.dialog_text = "是否拒绝该用户加入";
      this.isDialog = true;
    },
    agree(status) {
      this.auditStatus = status;
      this.dialog_text = "是否同意该用户加入";
      this.isDialog = true;
    },
    removeMessage() {
      this.dialog_text = "是否确认移除该消息";
      this.isDialog = true;
    },
    confirm() {
      if (this.dialog_text == "是否确认移除该消息") {
        let params = {
          id: this.id,
        };
        this.$axios
          .post(`${removeAppInfoUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.getDetail();
            }
          });
      } else {
        let params = {
          id: this.id,
          auditStatus: this.auditStatus,
          auditId: this.userId,
        };
        this.$axios
          .post(`${auditUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.getDetail();
            }
          });
      }
      this.isDialog = false;
    },
  },
};
</script>

<style lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;

  .dialog_text {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 86px 124px 58px;
    white-space: nowrap;
  }

  .remove_btn {
    width: 550px;
    height: 66px;
    border-radius: 10px;
    border: 2px solid;
    text-align: center;
    line-height: 66px;
    font-size: 32px;
    font-weight: 600;
    color: #fe9717;
    position: fixed;
    bottom: 248px;
    left: 50%;
    transform: translateX(-50%);
  }

  .agree_btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffc054 0%, #fe9716 100%);
    border-radius: 10px;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 66px;
    position: fixed;
    bottom: 248px;
    left: 50%;
    transform: translateX(-50%);
  }

  .refuse_btn {
    width: 550px;
    height: 66px;
    border-radius: 10px;
    border: 2px solid;
    text-align: center;
    line-height: 66px;
    font-size: 32px;
    font-weight: 600;
    color: #fe9717;
    position: fixed;
    bottom: 348px;
    left: 50%;
    transform: translateX(-50%);
  }

  .apply_content {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 60px;
    line-height: 42px;
  }

  .top_box {
    width: 100%;
    height: 220px;
    padding: 0 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .name {
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      margin-left: 26px;
    }

    .pic_box {
      width: 122px;
      height: 122px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
